import React, { forwardRef } from 'react'
import {
  Super1,
  Super2,
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  TextBodyLarge,
  TextBody,
  TextBodySmall,
  Label,
} from '@styles/vars/textStyles.style'

const ThisSuper1 = forwardRef(
  ({ children, as, color, maxWidth, ...props }, ref) => (
    <Super1 ref={ref} as={as} color={color} maxWidth={maxWidth} {...props}>
      {children}
    </Super1>
  )
)

const ThisSuper2 = forwardRef(
  ({ children, as, color, maxWidth, ...props }, ref) => (
    <Super2 ref={ref} as={as} color={color} maxWidth={maxWidth} {...props}>
      {children}
    </Super2>
  )
)

const ThisHeading1 = forwardRef(
  ({ children, as, color, maxWidth, ...props }, ref) => (
    <Heading1 ref={ref} as={as} color={color} maxWidth={maxWidth} {...props}>
      {children}
    </Heading1>
  )
)

const ThisHeading2 = forwardRef(
  ({ children, as, color, maxWidth, ...props }, ref) => (
    <Heading2 ref={ref} as={as} color={color} maxWidth={maxWidth} {...props}>
      {children}
    </Heading2>
  )
)

const ThisHeading3 = forwardRef(
  ({ children, as, color, maxWidth, ...props }, ref) => (
    <Heading3 ref={ref} as={as} color={color} maxWidth={maxWidth} {...props}>
      {children}
    </Heading3>
  )
)

const ThisHeading4 = forwardRef(
  ({ children, as, color, maxWidth, ...props }, ref) => (
    <Heading4 ref={ref} as={as} color={color} maxWidth={maxWidth} {...props}>
      {children}
    </Heading4>
  )
)

const ThisTextBodyLarge = forwardRef(
  ({ children, as, color, maxWidth, ...props }, ref) => (
    <TextBodyLarge
      ref={ref}
      as={as}
      color={color}
      maxWidth={maxWidth}
      {...props}
    >
      {children}
    </TextBodyLarge>
  )
)

const ThisTextBody = forwardRef(
  ({ children, as, color, maxWidth, ...props }, ref) => (
    <TextBody ref={ref} as={as} color={color} maxWidth={maxWidth} {...props}>
      {children}
    </TextBody>
  )
)

const ThisTextBodySmall = forwardRef(
  ({ children, as, color, maxWidth, ...props }, ref) => (
    <TextBodySmall
      ref={ref}
      as={as}
      color={color}
      maxWidth={maxWidth}
      {...props}
    >
      {children}
    </TextBodySmall>
  )
)

const ThisLabel = forwardRef(
  ({ children, as, color, maxWidth, ...props }, ref) => (
    <Label ref={ref} as={as} color={color} maxWidth={maxWidth} {...props}>
      {children}
    </Label>
  )
)

export {
  ThisSuper1 as Super1,
  ThisSuper2 as Super2,
  ThisHeading1 as Heading1,
  ThisHeading2 as Heading2,
  ThisHeading3 as Heading3,
  ThisHeading4 as Heading4,
  ThisTextBodyLarge as TextBodyLarge,
  ThisTextBody as TextBody,
  ThisTextBodySmall as TextBodySmall,
  ThisLabel as Label,
}
