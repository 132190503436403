import styled from 'styled-components'
import { font } from '@styles/vars/font.style'
import { clamp } from '@styles/utils/conversion.style'

export const textStylesConfig = {
  super1: {
    family: font.primary.family,
    weight: font.primary.weight.medium,
    size: {
      min: 68,
      max: 120,
    },
    lineHeight: 0.95,
    letterSpacing: 0,
    uppercase: true,
  },
  super2: {
    family: font.primary.family,
    weight: font.primary.weight.medium,
    size: {
      min: 54,
      max: 80,
    },
    lineHeight: 0.95,
    letterSpacing: 0,
    uppercase: true,
  },
  heading1: {
    family: font.primary.family,
    weight: font.primary.weight.medium,
    size: {
      min: 42,
      max: 60,
    },
    lineHeight: 0.95,
    letterSpacing: 0,
    uppercase: true,
  },
  heading2: {
    family: font.primary.family,
    weight: font.primary.weight.medium,
    size: {
      min: 28,
      max: 40,
    },
    lineHeight: 0.95,
    letterSpacing: 0,
    uppercase: true,
  },
  heading3: {
    family: font.secondary.family,
    weight: font.secondary.weight.semibold,
    size: {
      min: 20,
      max: 28,
    },
    lineHeight: 1.2,
    letterSpacing: 0,
  },
  heading4: {
    family: font.secondary.family,
    weight: font.secondary.weight.semibold,
    size: {
      min: 20,
      max: 21,
    },
    lineHeight: 1.2,
    letterSpacing: 0,
  },
  bodyLarge: {
    family: font.secondary.family,
    weight: font.secondary.weight.medium,
    size: {
      min: 21,
      max: 28,
    },
    lineHeight: 1.4,
    letterSpacing: -0.01,
  },
  body: {
    family: font.secondary.family,
    weight: font.secondary.weight.medium,
    size: {
      min: 18,
      max: 21,
    },
    lineHeight: 1.4,
    letterSpacing: 0,
  },
  bodySmall: {
    family: font.secondary.family,
    weight: font.secondary.weight.medium,
    size: {
      min: 18,
      max: 18,
    },
    lineHeight: 1.4,
    letterSpacing: 0,
  },
  label: {
    family: font.secondary.family,
    weight: font.secondary.weight.medium,
    size: {
      min: 14,
      max: 16,
    },
    lineHeight: 1,
    letterSpacing: 0.02,
    uppercase: true,
  },
}

export const getStyles = style => `
  max-width: ${style.maxWidth ? `${style.maxWidth}em` : ''};
  ${clamp('font-size', style.size.min, style.size.max)}
  font-family: ${style.family};
  font-weight: ${style.weight};
  line-height: ${style.lineHeight};
  ${style.letterSpacing ? `letter-spacing: ${style.letterSpacing}em` : ''};
  ${style.uppercase ? 'text-transform: uppercase' : ''};
  color: ${style.color ? style.color : ''};
`

export const Super1 = styled.p`
  ${props =>
    getStyles({
      ...textStylesConfig.super1,
      maxWidth: props.maxWidth,
      color: props.color,
    })}
`

export const Super2 = styled.p`
  ${props =>
    getStyles({
      ...textStylesConfig.super2,
      maxWidth: props.maxWidth,
      color: props.color,
    })}
`

export const Heading1 = styled.p`
  ${props =>
    getStyles({
      ...textStylesConfig.heading1,
      maxWidth: props.maxWidth,
      color: props.color,
    })}
`

export const Heading2 = styled.p`
  ${props =>
    getStyles({
      ...textStylesConfig.heading2,
      maxWidth: props.maxWidth,
      color: props.color,
    })}
`

export const Heading3 = styled.p`
  ${props =>
    getStyles({
      ...textStylesConfig.heading3,
      maxWidth: props.maxWidth,
      color: props.color,
    })}
`

export const Heading4 = styled.p`
  ${props =>
    getStyles({
      ...textStylesConfig.heading4,
      maxWidth: props.maxWidth,
      color: props.color,
    })}
`

export const TextBodyLarge = styled.p`
  ${props =>
    getStyles({
      ...textStylesConfig.bodyLarge,
      maxWidth: props.maxWidth,
      color: props.color,
    })}
`

export const TextBody = styled.p`
  ${props =>
    getStyles({
      ...textStylesConfig.body,
      maxWidth: props.maxWidth,
      color: props.color,
    })}
`

export const TextBodySmall = styled.p`
  ${props =>
    getStyles({
      ...textStylesConfig.bodySmall,
      maxWidth: props.maxWidth,
      color: props.color,
    })}
`

export const Label = styled.p`
  ${props =>
    getStyles({
      ...textStylesConfig.label,
      maxWidth: props.maxWidth,
      color: props.color,
    })}

  ${props =>
    props.dot &&
    `
      padding-left: 1.1rem;
      position: relative;
  
      &:before {
        background-color: currentColor;
        border-radius: 50%;
        content: '';
        display: block;
        height: 0.6rem;
        left: 0;
        margin-top: -0.3rem;
        position: absolute;
        top: 50%;
        width: 0.6rem;
      }
    `}
`
