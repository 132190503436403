import styled, { css } from 'styled-components'
import { font } from '@styles/vars/font.style'
import { colors } from '@styles/vars/colors.style'
import { clamp } from '@styles/utils/conversion.style'
import { cubicBezier } from '@styles/vars/cubicBezier.style'

const minFontSize = 16
const maxFontSize = 18

export const ButtonMain = styled.button`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: ${props => props.fill && '100%'};
  padding: ${7.5 / maxFontSize}em ${22.5 / maxFontSize}em;
  cursor: pointer;
  font-family: ${font.secondary.family};
  font-weight: ${font.secondary.medium};
  ${clamp('font-size', minFontSize, maxFontSize)}
  text-align: center;
  text-decoration: none;
  border: 1px solid transparent;
  border-radius: ${100 / maxFontSize}em;
  box-sizing: border-box;
  overflow: hidden;
  letter-spacing: 0.01em;
  white-space: nowrap;

  &[disabled] {
    opacity: 0.5;
    cursor: default;
    color: ${colors.light};
    background-color: ${colors.dark};
    border-color: ${colors.grey};
  }

  ${({ variant }) => {
    if (variant === 'primary') {
      return ButtonPrimary
    }

    if (variant === 'secondary') {
      return ButtonSecondary
    }

    if (variant === 'tertiary') {
      return ButtonTertiary
    }
  }}

  ${({ buttonSize }) => {
    if (buttonSize === 'large') {
      return ButtonLarge
    }
  }}

  &:hover {
    svg {
      &:nth-child(1) {
        transform: ${props => props.iconOrientation === 'horizontal'
          ? 'translateX(105%)'
          : 'translateY(-105%)'
        };
        transition-timing-function: ${cubicBezier.in};
        transition-delay: 0s;
      }

      &:nth-child(2) {
        transform: translate(0);
        transition-timing-function: ${cubicBezier.out};
        transition-delay: 0.3s;
      }
    }
  }
`

const ButtonPrimary = css`
  color: ${colors.dark};
  background-color: ${colors.light};
  border-color: ${colors.light};
  transition-property: background-color, color;
  transition-duration: 0.2s;
  transition-timing-function: ${cubicBezier.inOut};

  :not(:disabled):hover {
    color: ${colors.dark};
    background-color: ${colors.light};
    border-color: ${colors.light};
  }

  svg {
    color: ${colors.blue};
  }
`

const ButtonSecondary = css`
  color: ${colors.light};
  background-color: ${colors.blue};
  border-color: ${colors.blue};
  transition-property: background-color, color;
  transition-duration: 0.2s;
  transition-timing-function: ${cubicBezier.inOut};

  :not(:disabled):hover {
    color: ${colors.light};
    background-color: ${colors.blue};
    border-color: ${colors.blue};
  }

  svg {
    color: ${colors.lightblue};
  }
`

const ButtonTertiary = css`
  color: ${colors.dark};
  background-color: transparent;
  border-color: ${colors.blue};
  transition-property: background-color, color;
  transition-duration: 0.2s;
  transition-timing-function: ${cubicBezier.inOut};

  :not(:disabled):hover {
    color: ${colors.dark};
    background-color: transparent;
    border-color: ${colors.blue};
  }

  svg {
    color: ${colors.lightblue};
  }
`

const ButtonLarge = css`
  ${clamp('font-size', minFontSize, 21)}
  padding: ${10 / maxFontSize}em ${31 / 21}em;
`

export const ButtonContent = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;

  > * + * {
    margin-left: 0.5em;
  }
`

export const ButtonIcon = styled.span`
  display: grid;
  overflow: hidden;
  position: relative;
  width: ${props => props.iconWidth || 1}em;

  svg {
    grid-area: 1 / 1;
    transition: transform 0.4s ${cubicBezier.out};
    width: 100%;

    &:nth-child(1) {
      transition-delay: 0.3s;
      transform: translate(0);
    }

    &:nth-child(2) {
      transform: ${props => props.iconOrientation === 'horizontal'
        ? 'translateX(-105%)'
        : 'translateY(105%)'
      };
      transition-timing-function: ${cubicBezier.in};
      transition-delay: 0s;
    }
  }
`

export const ButtonBackground = styled.span`
  background: linear-gradient(
    180deg,
    rgba(169, 223, 241, 0) 0%,
    rgba(169, 223, 241, 0) 33%,
    rgba(169, 223, 241, 1) 66%,
    rgba(169, 223, 241, 1) 100%
  );
  bottom: -200%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateZ(0);
  transition: transform 0.6s ${cubicBezier.inOut};
  z-index: 1;

  ${ButtonMain}:hover & {
    transform: translateY(-66%) translateZ(0);
  }
`

export const ButtonText = styled.span``
