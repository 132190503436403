import React, { useRef, useEffect } from 'react'
import gsap from 'gsap'
import { hideMobileNav, hidePageMask, useStore } from '@Store'
import { TransitionMaskWrapper, TransitionMaskBlock } from './index.style'
import { animation } from '@styles/vars/animation.style'

const TransitionMask = () => {
  const [store, dispatch] = useStore()
  const { smoothScroll } = store
  const maskRef = useRef()
  const smoothScrollRef = useRef()

  useEffect(() => {
    smoothScrollRef.current = smoothScroll
  }, [smoothScroll])

  useEffect(() => {
    const tl = gsap.timeline()

    if (store.showPageMask) {
      const mask = maskRef.current

      hideMobileNav(dispatch)

      tl.set(mask, { transformOrigin: '50% 100%' })
        .to(mask, {
          scaleY: 1,
          duration: animation.maskShowDuration,
          ease: 'power2.inOut',
          onComplete: () => {
            if (smoothScrollRef.current) {
              smoothScrollRef.current.scrollTop(0)
            }
          },
        })
        .set(mask, { transformOrigin: '50% 0%' })
        .to(mask, {
          scaleY: 0,
          duration: animation.maskHideDuration,
          ease: 'power2.inOut',
          onComplete: () => hidePageMask(dispatch),
        })
    }

    return () => {
      if (tl) {
        tl.kill()
      }
    }
  }, [store.showPageMask, dispatch])

  return (
    <TransitionMaskWrapper show={store.showPageMask}>
      <TransitionMaskBlock ref={maskRef} />
    </TransitionMaskWrapper>
  )
}

export default TransitionMask
