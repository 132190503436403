import styled from 'styled-components'
import { setVh } from '@styles/utils/conversion.style'
import { colors } from '@styles/vars/colors.style'
import { mq } from '@styles/vars/media-queries.style'
import { cubicBezier } from '@styles/vars/cubicBezier.style'
import { clamp } from '@styles/utils/conversion.style'
import { breakpoints } from '@styles/vars/breakpoints.style'
import { zIndex } from '@styles/vars/zIndex.style'
import { PageTransitionLinkMain } from '@components/PageTransitionLink/index.style'
import { ButtonMain } from '@components/Button/index.style'
import { SearchFormLabel } from './SearchForm/index.style'
import { Heading1, TextBody } from '@styles/vars/textStyles.style'

export const HeaderContainer = styled.header`
  background-color: ${colors.light};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: ${zIndex.header};
  transition: background-color 0.3s ease;

  // Set alt header styling for homepage
  ${props =>
    props.theme.main === 'bright' &&
    `
    background-color: ${colors.darkblue};
  `}

  // Set alt header styling for microsite
  ${props =>
    props.theme.main === 'microsite' &&
    `
    background-color: transparent;
  `}

  // Set alt header styling for homepage & microsite
  ${props =>
    props.theme.main !== 'default' &&
    `
    ${HeaderMenuItem} {
      p {
        transition: color 0.3s ease;
        color: ${colors.light}
      }
    }
  `}
`

export const HeaderWrapper = styled.div`
  position: relative;
  z-index: 13;
`

export const SkipToContent = styled.a`
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-200%);
`

export const HeaderMain = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${clamp('padding-bottom', 12, 38)};
  ${clamp('padding-top', 12, 38)};
`

export const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;

  [aria-current] {
    &:after {
      display: none;
    }
  }
`

export const HeaderCenter = styled.div`
  display: none;

  ${mq.desk} {
    display: block;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
`

export const HeaderLogo = styled.img`
  display: none;
  height: auto;
  position: relative;
  ${clamp('width', 160, 248, breakpoints.tabletL, breakpoints.contained)};
  z-index: ${zIndex.mobileNavButtons};

  ${mq.tabletL} {
    display: block;
  }
`

export const HeaderLogoMobile = styled.img`
  height: 3.6rem;
  width: 3.3rem;

  ${mq.tabletL} {
    display: none;
  }
`

export const HeaderNav = styled.nav`
  display: block;
`

export const HeaderMenu = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  text-align: right;
`

export const HeaderMenuItem = styled.li`
  margin-left: 1.4rem;
  position: relative;

  ${mq.deskM} {
    margin-left: 1.8rem;
  }

  ${mq.deskL} {
    margin-left: 2.4rem;
  }

  p {
    ${mq.tabletP} {
      font-size: 1.8rem;
    }
    ${mq.deskM} {
      ${clamp('font-size', 18, 21)};
    }
  }

  ${HeaderCenter} & {
    ${mq.deskL} {
      margin-left: 4.2rem;
    }
  }

  &:first-child {
    margin-left: 0;
  }

  > ${PageTransitionLinkMain}, > button:not(${ButtonMain}) {
    appearance: none;
    background: transparent;
    border: none;
    color: inherit;
    display: inline-flex;
    text-decoration: none;
  }

  ${TextBody} {
    align-items: center;
    display: flex;

    svg {
      margin-left: 0.8rem;
    }
  }

  [aria-current] {
    position: relative;

    ${mq.desk} {
      &:after {
        content: '';
        background-color: ${colors.lightblue};
        position: absolute;
        top: -0.5rem;
        left: -1rem;
        bottom: 0;
        right: 0;
        height: calc(100% + 1rem);
        width: calc(100% + 2rem);
        z-index: -1;
        ${clamp('border-radius', 12, 24)};
        padding: 1rem;

        ${mq.deskL} {
          left: -1.5rem;
          width: calc(100% + 3rem);
        }
      }
    }
  }

  ${PageTransitionLinkMain} {
    &:before {
      content: '';
      background-color: ${colors.blue};
      display: block;
      position: absolute;
      bottom: -0.2rem;
      left: 0;
      width: 100%;
      height: 0.2rem;
      transform: scale3D(0, 1, 1);
      will-change: transform;
      transform-origin: top right;
      transition: transform 0.6s ${cubicBezier.inOut};
    }

    &:hover {
      &:before {
        transform: scale3D(1, 1, 1);
        transform-origin: top left;
      }
    }

    ${mq.desk} {
      &[aria-current] {
        &:before {
          display: none;
        }
      }
    }
  }
`

export const HeaderRight = styled.div`
  position: relative;
  z-index: 2;

  ${HeaderMenuItem} {
    :first-child {
      ${mq.tabletPMax} {
        display: none;
      }
    }
  }
`

export const HeaderMenuItemChildren = styled.div`
  height: ${props => (props.show ? 'auto' : 0)};
  opacity: ${props => (props.show ? 1 : 0)};
  overflow: hidden;
  padding-top: 1.5rem;
  pointer-events: ${props => (props.show ? 'auto' : 'none')};
  transition: opacity 0.6s ${cubicBezier.inOut},
    visibility 0s ${props => (props.show ? 0 : 0.6)}s;
  visibility: ${props => (props.show ? 'visible' : 'hidden')};

  ${mq.desk} {
    height: auto;
    overflow: visible;
    position: absolute;
    left: 50%;
    top: 100%;
    transform: translateX(-50%);

    &:before {
      background-color: ${colors.lightblue};
      border-radius: 0.3rem;
      content: '';
      height: 2.7rem;
      left: 50%;
      position: absolute;
      top: 0;
      transform: translateX(-50%) translateY(0.5rem) rotateZ(45deg);
      width: 2.7rem;
    }

    &:after {
      bottom: 0;
      content: '';
      left: 0;
      position: absolute;
      right: 0;
      top: -100%;
      z-index: -1;
    }
  }
`

export const HeaderSubMenu = styled.ul`
  list-style-type: none;

  ${mq.desk} {
    background-color: ${colors.lightblue};
    border-radius: 2.4rem;
    padding: 2.2rem 4.4rem;
  }
`

export const HeaderSubMenuItem = styled.li`
  padding-bottom: 1rem;
  white-space: nowrap;
  text-align: center;

  &:last-child {
    padding-bottom: 0;
  }

  a:focus {
    outline: rgba(0, 0, 0, 0.8) auto 0.4rem;
  }

  a {
    position: relative;
    text-decoration: none;

    &:before {
      content: '';
      background-color: ${colors.blue};
      display: block;
      position: absolute;
      bottom: -0.2rem;
      left: 0;
      width: 100%;
      height: 0.2rem;
      transform: scale3D(0, 1, 1);
      will-change: transform;
      transform-origin: top right;
      transition: transform 0.6s ${cubicBezier.inOut};
    }

    &:hover {
      &:before {
        transform: scale3D(1, 1, 1);
        transform-origin: top left;
      }
    }
  }
`

export const MobileNav = styled.div`
  color: ${colors.light};
  display: grid;
  padding-top: 10rem;
  position: absolute;
  top: 0;
  left: 0;
  transform: ${props => (props.open ? 'translateX(0)' : 'translateX(-100%)')};
  width: 100%;
  ${setVh('height', 100, true)}
  overflow: scroll;
  z-index: ${zIndex.mobileNav};
  pointer-events: ${props => (props.open ? undefined : 'none')};
  text-align: center;
  background-color: ${colors.darkblue};
  transition: transform 1s ${cubicBezier.out};

  ${mq.desk} {
    display: none;
  }
`

export const MobileNavMain = styled.nav`
  grid-area: 1 / 1;
  opacity: ${props => (props.show ? 1 : 0)};
  transition: opacity 0.6s ${cubicBezier.inOut}, visibility 0s 0.6s;
  pointer-events: ${props => (props.show ? 'auto' : 'none')};
  visibility: ${props => (props.show ? 'visible' : 'hidden')};
`

export const MobileMenu = styled.ul`
  list-style: none;
`

export const MobileMenuItem = styled.li`
  padding-bottom: 2.8rem;
  position: relative;

  > ${PageTransitionLinkMain}, > button {
    appearance: none;
    background: transparent;
    border: none;
    color: inherit;
    display: inline-flex;
    text-decoration: none;
  }

  ${Heading1} {
    align-items: center;
    display: flex;

    svg {
      margin-left: 0.8rem;
    }
  }

  ${PageTransitionLinkMain} {
    p {
      position: relative;

      &:before {
        content: '';
        background-color: ${colors.blue};
        display: block;
        position: absolute;
        bottom: -0.5rem;
        left: 0;
        width: 100%;
        height: 0.2rem;
        transform: scale3D(0, 1, 1);
        will-change: transform;
        transform-origin: top right;
        transition: transform 0.6s ${cubicBezier.inOut};
      }
    }

    &:hover {
      p {
        &:before {
          transform: scale3D(1, 1, 1);
          transform-origin: top left;
        }
      }
    }
  }
`

export const MobileNavSearch = styled.div`
  color: ${colors.light};
  grid-area: 1 / 1;
  opacity: ${props => (props.show ? 1 : 0)};
  transition: opacity 0.6s ${cubicBezier.inOut} 0.4s;
  pointer-events: ${props => (props.show ? 'auto' : 'none')};
  visibility: ${props => (props.show ? 'visible' : 'hidden')};

  ${SearchFormLabel} {
    color: ${colors.light};
  }
`
