import React from 'react'
import {
  StickyCountdown2Inner,
  StickyCountdown2Title,
  StickyCountdown2Wrapper,
  StickyHideMobile,
} from './index.style'
import Container from '@components/Container'
import Grid from '@components/Grid'
import GridItem from '@components/GridItem'
import { TextBodySmall } from '@components/TextStyles'
import Spacer from '@components/Spacer'
import { colors } from '@styles/vars/colors.style'
import Button from '@components/Button'
import IconArrowRight from '@svgs/IconArrowRight'
import CountdownWeekends from '@components/CountdownWeekends'

const StickyCountdown2 = ({ title, text, link, weekends, show }) => {
  return (
    <StickyCountdown2Wrapper className="sticky-countdown-2" show={show}>
      <Spacer size={13} />
      <Container>
        <Grid>
          <GridItem mobileL={12} tabletL={7} desk={5}>
            <StickyCountdown2Inner>
              <CountdownWeekends weekends={weekends} />
              <StickyCountdown2Title color={colors.light}>
                {title}
              </StickyCountdown2Title>
            </StickyCountdown2Inner>
          </GridItem>
          <StickyHideMobile mobileL={3} mobileLStart={10} desk={7}>
            {text && (
              <TextBodySmall maxWidth={38} color={colors.light}>
                {text}
              </TextBodySmall>
            )}

            <Button
              type={link.page ? 'internalLink' : 'externalLink'}
              toPage={link.page ? link.page : null}
              href={link.url ? link.url : null}
              iconRight={<IconArrowRight />}
            >
              {link.text}
            </Button>
          </StickyHideMobile>
        </Grid>
      </Container>
      <Spacer size={13} />
    </StickyCountdown2Wrapper>
  )
}

export default StickyCountdown2
