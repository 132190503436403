import React from 'react'

const IconSearch = ({
  width = 15,
  height = 14,
  fill = 'currentColor',
  responsive = false,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 15 14"
      aria-hidden="true"
      focusable="false"
      {...svgAttributes}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.719 9.658c2.456 0 4.448-1.891 4.448-4.225 0-2.334-1.992-4.226-4.448-4.226-2.457 0-4.448 1.892-4.448 4.226s1.991 4.225 4.448 4.225Zm0 1.208c3.158 0 5.718-2.433 5.718-5.433S8.877 0 5.72 0C2.56 0 0 2.432 0 5.433c0 3 2.56 5.433 5.719 5.433Z"
        fill={fill}
      />
      <path
        d="m8.896 9.305.898-.854 4.943 4.695-.899.854-4.942-4.695Z"
        fill={fill}
      />
    </svg>
  )
}

export default IconSearch
