import { clamp } from '@styles/utils/conversion.style'
import { colors } from '@styles/vars/colors.style'
import { mq } from '@styles/vars/media-queries.style'
import { Heading2 } from '@styles/vars/textStyles.style'
import styled from 'styled-components'

export const CountdownWeekendsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.8rem;

  ${({ theme }) =>
    theme === 'large' &&
    `
    ${mq.tabletLMax} {
      justify-content: center;
    }
  `}
`

export const CountdownWeekendsTitle = styled(Heading2)`
  background: ${colors.light};
  border-radius: 0.6rem;
  ${clamp('font-size', 28, 32)};
  padding: 0.5em 0.4em;
  white-space: nowrap;

  ${({ theme }) =>
    theme === 'large' &&
    `
    margin-right: 0;
    ${clamp('font-size', 36, 96)};
    padding: 0.3em 0.45em;

    ${mq.tabletL} {
      padding: 0.25em;
    }
  `}
`
