import { colors } from '@styles/vars/colors.style'
import { cubicBezier } from '@styles/vars/cubicBezier.style'
import { mq } from '@styles/vars/media-queries.style'
import styled from 'styled-components'

export const SearchFormWrapper = styled.div`
  display: ${props => (props.mobile ? `block` : `none`)};

  ${mq.desk} {
    display: block;
  }

  ${props =>
    props.theme.main !== 'default' &&
    `
      color: ${colors.light};
    `}
`

export const SearchFormForm = styled.form`
  line-height: 1.6;
  position: relative;

  ${mq.desk} {
    max-width: 13rem;
  }

  ${mq.deskM} {
    max-width: 20rem;
  }

  &:after {
    background-color: currentColor;
    bottom: 0;
    content: '';
    height: 0.1rem;
    position: absolute;
    left: 0;
    right: 0;
  }

  input {
    padding-right: 2rem;
  }
`

export const SearchFormInput = styled.input`
  background: transparent;
  border: none;
  color: currentColor;
  cursor: pointer;
  line-height: inherit;
  width: 100%;

  // Update for accessibility
  &:focus {
    outline: none;
  }
`

export const SearchFormLabel = styled.label`
  bottom: 0;
  color: ${colors.darkblue};
  cursor: pointer;
  left: 0;
  line-height: inherit;
  opacity: ${props => (props.value ? 0 : 0.5)};
  pointer-events: ${props => (props.value ? 'none' : 'auto')};
  position: absolute;
  transition: opacity 0.3s ${cubicBezier.inOut};

  ${SearchFormInput}:focus + & {
    opacity: 0;
    pointer-events: none;
  }
`

export const SearchFormIcon = styled.button`
  appearance: none;
  background: transparent;
  border: none;
  bottom: 0.7rem;
  color: currentColor;
  cursor: pointer;
  display: grid;
  overflow: hidden;
  position: absolute;
  right: 0;

  > svg {
    grid-area: 1 / 1;
    transition: transform 0.4s ${cubicBezier.out};

    &:nth-child(1) {
      transition-delay: 0.3s;
    }

    &:nth-child(2) {
      transform: translateY(100%);
      transition-timing-function: ${cubicBezier.in};
      transition-delay: 0s;
    }

    ${SearchFormForm}:hover &,
    ${SearchFormInput}:focus + * + & {
      &:nth-child(1) {
        transform: translateY(-100%);
        transition-timing-function: ${cubicBezier.in};
        transition-delay: 0s;
      }
      &:nth-child(2) {
        transform: translateY(0);
        transition-timing-function: ${cubicBezier.out};
        transition-delay: 0.3s;
      }
    }
  }
`
