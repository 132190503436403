import React, { useRef, useState, useContext } from 'react'
import { ThemeContext } from 'styled-components'
import PropTypes from 'prop-types'
import { useTriggerTransition } from 'gatsby-plugin-transition-link'
import {
  SearchFormWrapper,
  SearchFormForm,
  SearchFormInput,
  SearchFormLabel,
  SearchFormIcon,
} from './index.style'
import { TextBodySmall } from '@components/TextStyles'
import IconSearch from '@svgs/IconSearch'
import { animation } from '@styles/vars/animation.style'
import { showPageMask, updatePathname, useStore } from '@Store'

const SearchForm = ({ id, focusEvent, submitEvent, mobile }) => {
  const [store, dispatch] = useStore()

  const [value, setValue] = useState('')

  const inputRef = useRef()

  const $skipToContent =
    typeof document !== 'undefined'
      ? document.getElementById('skip-to-content')
      : null

  // Trigger page change - requires custom transition
  const triggerTransition = useTriggerTransition({
    exit: {
      delay: animation.maskShowDuration,
      length: 0,
      zIndex: 2,
      trigger: () => {
        if ($skipToContent) $skipToContent.focus()
      },
    },
    entry: {
      delay: 0,
      length: animation.maskHideDuration,
      zIndex: 1,
    },
  })

  const handleInput = () => {
    setValue(inputRef.current.value)
  }

  const handleSubmit = e => {
    if (e) e.preventDefault()
    if (!value) inputRef.current.focus()
    if (submitEvent) submitEvent()

    const to = `/search?s=${value}`

    if (
      to.indexOf(store.pathname) === -1 ||
      to.length !== store.pathname.length
    ) {
      updatePathname(dispatch, to)
      showPageMask(dispatch)

      triggerTransition({
        to: to,
      })

      // Clear search form and focus
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.blur()
          inputRef.current.value = ''
        }
      }, animation.maskHideDuration * 1000)
    }
  }

  return (
    <SearchFormWrapper theme={useContext(ThemeContext)} mobile={mobile}>
      <SearchFormForm onSubmit={handleSubmit}>
        <SearchFormInput
          type="text"
          id={`searchinput-${id}`}
          autoComplete="off"
          ref={inputRef}
          onKeyUp={handleInput}
          onFocus={focusEvent || null}
        />
        <SearchFormLabel htmlFor={`searchinput-${id}`} value={value}>
          <TextBodySmall>Search</TextBodySmall>
        </SearchFormLabel>
        <SearchFormIcon type="submit">
          <IconSearch />
          <IconSearch />
        </SearchFormIcon>
      </SearchFormForm>
    </SearchFormWrapper>
  )
}

SearchForm.propTypes = {
  id: PropTypes.string.isRequired,
  focusEvent: PropTypes.func,
  submitEvent: PropTypes.func,
  mobile: PropTypes.bool,
}

export default SearchForm
