import React from 'react'
import PropTypes from 'prop-types'
import {
  ButtonContent,
  ButtonMain,
  ButtonIcon,
  ButtonText,
} from './index.style'
import PageTransitionLink from '@components/PageTransitionLink'

/**
 *
 * @prop type
 * Defines the markup and functionality
 * @prop variant
 * Defines the visual style
 * @prop href
 * If defined this renders the component as an anchor element with an href attribute <a href={href} />
 * @prop to
 * If type is internalLink this is the url used in the PageTransitionLink component
 * @prop label
 * If defined this adds an aria-label to the component. Necessary for icon only buttons.
 */

const Button = ({
  type,
  variant,
  buttonSize,
  disabled,
  href,
  to,
  toPage,
  label,
  fill,
  iconLeft,
  iconRight,
  iconWidth,
  onClick,
  submit,
  children,
  iconOrientation,
  ...props
}) => {
  const content = (
    <ButtonContent>
      {iconLeft && (
        <ButtonIcon iconOrientation={iconOrientation} iconWidth={iconWidth} left>
          {iconLeft}
          {iconLeft}
        </ButtonIcon>
      )}
      {children && <ButtonText>{children}</ButtonText>}
      {iconRight && (
        <ButtonIcon iconOrientation={iconOrientation} iconWidth={iconWidth} right>
          {iconRight}
          {iconRight}
        </ButtonIcon>
      )}
    </ButtonContent>
  )

  return (
    <>
      {type === 'button' && (
        <ButtonMain
          as="button"
          type={submit ? 'submit' : undefined}
          variant={variant}
          disabled={disabled}
          aria-label={label}
          fill={fill}
          onClick={onClick}
          buttonSize={buttonSize}
          iconOrientation={iconOrientation}
        >
          {content}
        </ButtonMain>
      )}

      {type === 'externalLink' && (
        <ButtonMain
          as="a"
          href={href}
          target="_blank"
          variant={variant}
          aria-label={label}
          fill={fill}
          onClick={onClick}
          buttonSize={buttonSize}
          iconOrientation={iconOrientation}
          {...props}
        >
          {content}
        </ButtonMain>
      )}

      {type === 'internalLink' && (
        <PageTransitionLink to={to} toPage={toPage} onClick={onClick}>
          <ButtonMain
            as="span"
            variant={variant}
            disabled={disabled}
            aria-label={label}
            fill={fill}
            buttonSize={buttonSize}
            iconOrientation={iconOrientation}
          >
            {content}
          </ButtonMain>
        </PageTransitionLink>
      )}
    </>
  )
}

Button.propTypes = {
  type: PropTypes.oneOf(['button', 'internalLink', 'externalLink']).isRequired,
  variant: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
  disabled: PropTypes.bool,
  inline: PropTypes.bool,
  fill: PropTypes.number,
  iconWidth: PropTypes.number,
  href: function (props, propName) {
    if (
      props['type'] === 'externalLink' &&
      (props[propName] === undefined || typeof props[propName] !== 'string')
    ) {
      return new Error('Please provide a href prop!')
    }
  },
  to: function (props, propName) {
    if (
      props['type'] === 'internalLink' &&
      props.toPage === undefined &&
      (props[propName] === undefined || typeof props[propName] !== 'string')
    ) {
      return new Error('Please provide a to or toPage prop!')
    }
  },
  toPage: function (props, propName) {
    if (
      props['type'] === 'internalLink' &&
      props.to === undefined &&
      (props[propName] === undefined || typeof props[propName] !== 'object')
    ) {
      return new Error('Please provide a to or toPage prop!')
    }
  },
  label: PropTypes.string,
  iconLeft: PropTypes.node,
  iconRight: PropTypes.node,
  submit: PropTypes.bool,
  onClick: function (props, propName) {
    if (
      props['type'] === 'button' &&
      (props[propName] === undefined || typeof props[propName] !== 'function')
    ) {
      return new Error('Please provide an onClick function!')
    }
  },
  children: PropTypes.node.isRequired,
  buttonSize: PropTypes.oneOf(['large', 'small']),
  iconOrientation: PropTypes.oneOf(['horizontal', 'vertical'])
}

Button.defaultProps = {
  type: 'button',
  variant: 'primary',
  iconWidth: 0.7,
  iconOrientation: 'horizontal'
}

export default Button
