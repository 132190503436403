import React from 'react'

const IconArrowDown = ({
  width = 12,
  height = 16,
  stroke = 'currentColor',
  responsive = true,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 12 16"
      aria-hidden="true"
      focusable="false"
      {...svgAttributes}
    >
      <path
        d="M11 9.08588L6 14.0859M6 14.0859L1 9.08588M6 14.0859L6 1.91431"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default IconArrowDown
