import { useEffect, useLayoutEffect, useRef } from 'react'
import gsap from 'gsap'
import { ScrollSmoother } from 'gsap/ScrollSmoother'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { useStore } from '@Store/'
import { setSmoothScroll } from '@Store/'

gsap.registerPlugin(ScrollTrigger, ScrollSmoother)

const SmoothScroll = ({ callbacks }) => {
  const [store, dispatch] = useStore()
  const { smoothScroll } = store
  const smoothScrollRef = useRef()

  useEffect(() => {
    smoothScrollRef.current = smoothScroll
  }, [smoothScroll])

  useEffect(() => {
    const scroll = ScrollSmoother.create({
      smooth: 1.2,
      smoothTouch: false,
      effects: false,
      normalizeScroll: false,
    })
    setSmoothScroll(dispatch, scroll)

    return () => {
      if (smoothScrollRef.current) {
        smoothScrollRef.current.kill()
      }
    }
  }, [callbacks.pathname, dispatch])

  useLayoutEffect(() => {
    ScrollTrigger.refresh()
  }, [store.resized])

  return null
}

export default SmoothScroll
