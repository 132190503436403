import { clamp } from '@styles/utils/conversion.style'
import { breakpoints } from '@styles/vars/breakpoints.style'
import { colors } from '@styles/vars/colors.style'
import { mq } from '@styles/vars/media-queries.style'
import { Heading2, TextBodySmall } from '@styles/vars/textStyles.style'
import styled from 'styled-components'

export const CountdownWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.8rem;

  ${({ theme }) =>
    theme === 'large' &&
    `
    ${mq.tabletLMax} {
      justify-content: center;
    }
  `}
`

export const CountdownItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${colors.light};
  border-radius: 0.6rem;
  overflow: hidden;

  ${({ theme }) =>
    theme === 'small'
      ? `
    width: 4.5rem;
    padding: 0.7rem 0.8rem;
  `
      : `
    ${clamp('width', 48, 142, breakpoints.tiny, breakpoints.contained)};
    ${clamp('padding-left', 4, 29, breakpoints.tiny, breakpoints.contained)};
    ${clamp('padding-right', 4, 29, breakpoints.tiny, breakpoints.contained)};
    padding-top: 0.8rem;
    padding-bottom: 1.8rem;
  `}
`

export const CountdownTitle = styled(Heading2)`
  color: ${colors.blue};

  ${({ theme }) =>
    theme === 'small'
      ? `
  ${clamp('font-size', 18, 32, breakpoints.tiny, breakpoints.contained)}
  `
      : `
      ${clamp('font-size', 38, 102, breakpoints.tiny, breakpoints.contained)}
  `}
`
export const CountdownLabel = styled(TextBodySmall)`
  ${({ theme }) =>
    theme === 'small'
      ? `

font-size: 1rem;
`
      : `
    ${clamp('font-size', 8, 18, breakpoints.tiny, breakpoints.contained)}
`}
`
