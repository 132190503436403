export const font = {
  primary: {
    family: 'GTAmerica',
    weight: {
      medium: 500,
    },
  },
  secondary: {
    family: 'PPNeueMontreal',
    weight: {
      medium: 500,
      semibold: 600,
    },
  },
}
