import styled from 'styled-components'
import { clamp } from '@styles/utils/conversion.style'
import { mq } from '@styles/vars/media-queries.style'

export const LocationButtons = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  ${clamp('margin-top', 16, 24)};

  button:first-child {
    ${clamp('margin-bottom', 16, 24)};

    ${mq.tiny} {
      margin-bottom: 0;
    }
  }

  ${mq.tiny} {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`