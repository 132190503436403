import { colors } from '@styles/vars/colors.style'
import { cubicBezier } from '@styles/vars/cubicBezier.style'
import React from 'react'

const IconDropdown = ({
  width = 9,
  height = 6,
  stroke = colors.blue,
  responsive = false,
  open = true,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 9 6"
      aria-hidden="true"
      focusable="false"
      style={{
        transform: `rotateZ(${open ? 0 : 180}deg)`,
        transformOrigin: '50% 50%',
        transition: `transform 0.3s ${cubicBezier.inOut}`,
      }}
      {...svgAttributes}
    >
      <path
        d="M1 4.293 4.293 1l3.293 3.293"
        fill="none"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default IconDropdown
