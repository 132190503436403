import styled from 'styled-components'
import { colors } from '@styles/vars/colors.style'
import { zIndex } from '@styles/vars/zIndex.style'
import { mq } from '@styles/vars/media-queries.style'

export const MenuToggleWrapper = styled.div`
  margin-left: 2.8rem;
  position: relative;
  z-index: ${zIndex.mobileNavButtons};

  // Set alt header styling for homepage & microsite
  ${props =>
    props.theme.main !== 'default' &&
    `
      ${MenuToggleBar} {
        &:before {
          background-color: ${colors.light};
        }
      }
    `}
`

export const MenuToggleMain = styled.button`
  position: relative;
  width: 3rem;
  height: 3rem;
  background-color: transparent;
  border: none;
  vertical-align: middle;

  ${mq.desk} {
    display: none;
  }
`

export const MenuToggleBar = styled.div`
  position: absolute;
  top: calc(50% - 0.05rem);
  left: 0;
  right: 0;
  height: 0.1rem;

  &:before {
    background-color: ${props => (props.open ? colors.light : colors.darkblue)};
    content: '';
    display: block;
    height: 100%;
    width: 100%;
  }

  :first-child {
    transform: translateY(-0.6rem);
  }

  :nth-child(2) {
    right: 0.5rem;
    transform-origin: 0% 0%;
  }

  :last-child {
    transform: translateY(0.6rem);
  }
`
