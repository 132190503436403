import { CountdownWrapper } from '@components/Countdown/index.style'
import { GridMain } from '@components/Grid/index.style'
import { GridItemMain } from '@components/GridItem/index.style'
import { clamp } from '@styles/utils/conversion.style'
import { breakpoints } from '@styles/vars/breakpoints.style'
import { mq } from '@styles/vars/media-queries.style'
import { Heading2, TextBodySmall } from '@styles/vars/textStyles.style'
import styled from 'styled-components'

export const StickyCountdown2Wrapper = styled.div`
  background: linear-gradient(90deg, #3c07ac 0%, #cc3bff 100%);
  display: ${props => (props.show ? 'block' : 'none')};
  ${clamp('height', 81, 88)};

  ${TextBodySmall} {
    display: none;
    margin-right: 2.4rem;

    ${mq.desk} {
      display: block;
    }

    ${mq.deskM} {
      margin-right: 3.2rem;
    }
  }

  ${GridMain} {
    align-items: center;
    justify-content: center;
  }
`

export const StickyCountdown2Title = styled(Heading2)`
  ${clamp('font-size', 22, 32, breakpoints.deskM, breakpoints.contained)};
  margin-left: 2.4rem;
  max-width: 12rem;

  @media (min-width: 480px) {
    max-width: none;
  }
`

export const StickyHideMobile = styled(GridItemMain)`
  display: none;

  ${mq.tabletL} {
    align-items: center;
    display: flex;
    justify-content: flex-end;
  }
`
export const StickyCountdown2Inner = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  ${CountdownWrapper} {
    ${mq.tabletL} {
      ${clamp(
        'margin-right',
        18,
        62,
        breakpoints.tabletL,
        breakpoints.contained
      )}
    }
  }
`
