import React, { useEffect, useRef, useContext } from 'react'
import { ThemeContext } from 'styled-components'
import gsap from 'gsap'
import { hideMobileNav, showMobileNav, useStore } from '@Store'
import { disablePageScroll, enablePageScroll } from '@utils'
import { MenuToggleMain, MenuToggleBar, MenuToggleWrapper } from './index.style'

const MenuToggle = () => {
  const [store, dispatch] = useStore()
  const { mobileNavIsOpen } = store
  const toggleRef = useRef()
  const barRefA = useRef()
  const barRefB = useRef()
  const barRefC = useRef()

  useEffect(() => {
    const tlOpen = gsap.timeline({ paused: true })
    const tlClose = gsap.timeline({ paused: true })

    tlOpen
      .to(barRefB.current, {
        y: 0,
        scaleX: 0,
        duration: 0.3,
        ease: 'power2.out',
      })
      .to(
        barRefA.current,
        {
          y: 0,
          rotate: 45,
          duration: 0.3,
          ease: 'power2.inOut',
        },
        0.2
      )
      .to(
        barRefC.current,
        {
          y: 0,
          rotate: -45,
          duration: 0.3,
          ease: 'power2.inOut',
        },
        0.2
      )

    tlClose
      .to(barRefB.current, {
        y: 0,
        scaleX: 1,
        duration: 0.3,
        ease: 'power2.inOut',
      })
      .to(
        barRefA.current,
        {
          y: -6,
          rotate: 0,
          duration: 0.3,
          ease: 'power2.inOut',
        },
        0
      )
      .to(
        barRefC.current,
        {
          y: 6,
          rotate: 0,
          duration: 0.3,
          ease: 'power2.inOut',
        },
        0
      )

    if (mobileNavIsOpen) {
      disablePageScroll()
      tlOpen.play()
    } else {
      enablePageScroll()
      tlClose.play()
    }

    return () => {
      tlOpen.kill()
      tlClose.kill()
    }
  }, [mobileNavIsOpen])

  const handleClick = () => {
    if (mobileNavIsOpen) {
      hideMobileNav(dispatch)
    } else {
      showMobileNav(dispatch)
    }
  }

  return (
    <MenuToggleWrapper ref={toggleRef} theme={useContext(ThemeContext)}>
      <MenuToggleMain onClick={handleClick} aria-label="Toggle menu">
        <MenuToggleBar ref={barRefA} open={mobileNavIsOpen} />
        <MenuToggleBar ref={barRefB} open={mobileNavIsOpen} />
        <MenuToggleBar ref={barRefC} open={mobileNavIsOpen} />
      </MenuToggleMain>
    </MenuToggleWrapper>
  )
}

export default MenuToggle
