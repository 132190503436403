import React from 'react'

const IconArrowRight = ({
  width = 15,
  height = 12,
  stroke = 'currentColor',
  responsive = true,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 15 12"
      aria-hidden="true"
      focusable="false"
      {...svgAttributes}
    >
      <path
        d="m8.172 1 5 5m0 0-5 5m5-5H1"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default IconArrowRight
