import gsap from 'gsap'
import { CustomEase } from 'gsap/all'

gsap.registerPlugin(CustomEase)

export const easings = {
  in: '0.990, 0.125, 0.845, 0.575',
  out: '0.155, 0.435, 0.125, 0.990',
  inOut: '0.575, 0.155, 0.125, 0.990',
}

export const cubicBezier = {
  in: `cubic-bezier(${easings.in})`,
  out: `cubic-bezier(${easings.out})`,
  inOut: `cubic-bezier(${easings.inOut})`,
}

CustomEase.create('in', easings.in)
CustomEase.create('out', easings.out)
CustomEase.create('inOut', easings.inOut)
