export const colors = {
  dark: '#000000',
  light: '#ffffff',
  grey: '#EEEEEE',
  lightgrey: '#9E9EA7',
  darkblue: '#22223F',
  blue: '#2D68B2',
  lightblue: '#A9DFF1',
  blueborder: '#9DACE6'
}
