import styled from 'styled-components'
import {
  DialogOverlay as ReachOverlay,
  DialogContent as ReachContent,
} from '@reach/dialog'
import { transparentize } from 'polished'
import { colors } from '@styles/vars/colors.style'
import { clamp } from '@styles/utils/conversion.style'

export const ModalWrapper = styled(ReachOverlay)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow: auto;
  ${props => props.inset && clamp('padding', 24, 36)};
`

export const ModalOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: ${transparentize(0.2, colors.darkblue)};
  opacity: 0;
`

export const ModalContent = styled(ReachContent)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  outline: none;
  opacity: 0;
  pointer-events: none;
  ${props =>
    props.inset &&
    `
    background-color: ${colors.grey};
    max-width: 50rem;
    ${clamp('border-radius', 12, 24)};
    ${clamp('padding', 24, 36)};
  `}

  > * {
    pointer-events: auto;
  }
`
