export const breakpoints = {
  tiny: 320,
  mobile: 375,
  mobileL: 640,
  tabletP: 768,
  tabletL: 1024,
  desk: 1240,
  deskM: 1400,
  deskL: 1800,
  contained: 1920,
}
