import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import Modal from '@components/Modal'
import Button from '@components/Button'
import { TextBody } from '@components/TextStyles'
import IconArrowRight from '@svgs/IconArrowRight'
import { LocationButtons } from './index.style'

const Locator = ({ location }) => {
  const [originCountry, setOriginCountry] = useState('')
  const [open, setOpen] = useState(false)
  const [url, setUrl] = useState('')

  /**
   * Get users country from lat lng values
   */
  const getUserLocation = useCallback(latLng => {
    // Set google geocode here.
    const geocoder = new window.google.maps.Geocoder()

    // Reverse geocode users position
    geocoder
      .geocode({ latLng: latLng })
      .then(res => {
        if (res && res.results) {
          // Gets country code from geocode reverse
          const country = res.results.filter(address => {
            return address.types.includes('country')
          })

          if (country && country[0]) {
            setOriginCountry(country[0].formatted_address)
          }
        }
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  /**
   * Get users position via navigator api
   */
  const getUserPosition = useCallback(() => {
    if (
      localStorage.getItem('LTYA-location') ||
      !navigator ||
      !navigator.geolocation
    )
      return

    const success = position => {
      const lat = position.coords.latitude
      const lng = position.coords.longitude

      if (typeof window !== 'undefined' && window.google) {
        // Get LatLng object literal
        const latLng = new window.google.maps.LatLng(lat, lng)

        // Get user country from reverse geocode
        getUserLocation(latLng)
      }
    }

    const error = error => console.log(error.message)

    // Get users position
    navigator.geolocation.getCurrentPosition(success, error)
  }, [getUserLocation])

  /**
   * Set relevant user details
   */
  const setUserLocation = useCallback(() => {
    // Get site variables
    const site = {
      US: process.env.GATSBY_US_SITE,
      CA: process.env.GATSBY_CA_SITE,
    }

    // Check if country origins matches what is needed
    if (originCountry === 'United States') {
      setUrl(site.US)

      site.US === location.origin ? setOpen(false) : setOpen(true)
    } else if (originCountry === 'Canada') {
      setUrl(site.CA)

      site.CA === location.origin ? setOpen(false) : setOpen(true)
    }
  }, [originCountry, location])

  /**
   * Set session storage to avoid multiple instances
   */
  const setSessionLocation = () => {
    localStorage.setItem('LTYA-location', 'true')
  }

  /**
   * Dimsiss modal
   */
  const dismissModal = () => {
    setOpen(false)
  }

  /**
   * Update location and redirect
   */
  const acceptLocation = () => {
    setOpen(false)
    window.location.href = url
  }

  /**
   * Decline location and close
   */
  const declineLocation = () => {
    setSessionLocation()
    setOpen(false)
  }

  useEffect(() => {
    getUserPosition()
  }, [getUserPosition])

  useEffect(() => {
    setUserLocation()
  }, [originCountry, setUserLocation])

  return (
    <Modal
      isOpen={open}
      ariaLabel="Change Location"
      onDismiss={() => dismissModal()}
      inset={true}
    >
      <TextBody>
        We've noticed you're located in {originCountry}, would you like to view
        the {originCountry} site?
      </TextBody>

      <LocationButtons>
        <Button
          type="button"
          onClick={() => acceptLocation()}
          iconRight={<IconArrowRight />}
        >
          Yes
        </Button>
        <Button
          type="button"
          onClick={() => declineLocation()}
          iconRight={<IconArrowRight />}
        >
          No
        </Button>
      </LocationButtons>
    </Modal>
  )
}

Locator.propTypes = {
  location: PropTypes.object,
}

export default Locator
