import React from 'react'
import PropTypes from 'prop-types'
import { showPageMask, updatePathname, useStore } from '@Store'
import { animation } from '@styles/vars/animation.style'
import { PageTransitionLinkMain } from './index.style'
import { getInternalLinkSlug } from '@utils'

const PageTransitionLink = ({
  children,
  to,
  toPage,
  fill = false,
  onClick,
  ...props
}) => {
  const [store, dispatch] = useStore()

  const $skipToContent =
    typeof document !== 'undefined'
      ? document.getElementById('skip-to-content')
      : null

  // A toPage can be passed instead of a to. This allows for slug prefixes to be added for offers and news articles
  // toPage should be used anywhere an offer or news article can be referenced
  // internal { type } should also be added to the GraphQL query
  to = to || getInternalLinkSlug(toPage)

  // Handle same-page deployment links
  // if (
  //   pathname.replaceAll('/', '') === 'deployment' &&
  //   toPage?.internal?.type === 'ContentfulDeployment'
  // ) {
  //   return (
  //     <a
  //       href={`#`}
  //       onClick={e => {
  //         e.preventDefault()
  //         anchorScroll(smoothScroll, `#deployment-${toPage.contentful_id}`, 100)
  //       }}
  //     >
  //       {children}
  //     </a>
  //   )
  // } else {
  return (
    <PageTransitionLinkMain
      to={to}
      exit={{
        delay: animation.maskShowDuration,
        length: 0,
        zIndex: 2,
        trigger: () => {
          if ($skipToContent) $skipToContent.focus()
        },
      }}
      entry={{
        delay: 0,
        length: animation.maskHideDuration,
        zIndex: 1,
      }}
      fill={fill ? 'true' : undefined}
      {...props}
      onClick={() => {
        if (onClick) {
          onClick()
        }

        if (
          to.indexOf(store.pathname) === -1 ||
          to.length !== store.pathname.length
        ) {
          updatePathname(dispatch, to)
          showPageMask(dispatch)
        }
      }}
    >
      {children}
    </PageTransitionLinkMain>
  )
  // }
}

PageTransitionLink.propTypes = {
  children: PropTypes.node,
  fill: PropTypes.bool,
  to: PropTypes.string,
  toPage: PropTypes.object,
  onClick: PropTypes.func,
}

export default PageTransitionLink
