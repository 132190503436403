import React, { useEffect, useRef } from 'react'
import gsap from 'gsap'
import PropTypes from 'prop-types'
import { Phased } from 'recondition'
import { ModalContent, ModalOverlay, ModalWrapper } from './index.style'

export const modalStaggerDurationMs = 500
export const modalAnimationDurationMs = 1000
export const modalStaggerDurationS = modalStaggerDurationMs / 1000
export const modalAnimationDurationS = modalAnimationDurationMs / 1000

const ModalInner = ({
  isOpen,
  open,
  onDismiss,
  closeEvent,
  ariaLabel,
  children,
  inset,
}) => {
  const $overlay = useRef()
  const $content = useRef()
  const animate = useRef()

  useEffect(() => {
    animate.current = gsap.timeline({
      defaults: {
        ease: 'power3.inOut',
      },
    })

    return () => {
      if (animate.current) {
        animate.current.kill()
      }
    }
  }, [])

  useEffect(() => {
    if (animate.current) {
      animate.current.clear()
    }

    if (open) {
      const animateDelay = 0.01 // this is required so that Reach modal is rendered before animation is attempted

      gsap.delayedCall(animateDelay, () => {
        if (!$overlay.current || !$content.current) return

        const fullDuration = modalAnimationDurationS - animateDelay

        animate.current
          .to($overlay.current, {
            opacity: 1,
            duration: fullDuration,
          })
          .fromTo(
            $content.current,
            {
              opacity: 0,
            },
            {
              opacity: 1,
              duration: fullDuration,
            },
            modalStaggerDurationS
          )
      })
    } else {
      if (!$overlay.current || !$content.current) return

      animate.current
        .to($content.current, {
          opacity: 0,
          duration: modalAnimationDurationS,
        })
        .to(
          $overlay.current,
          {
            opacity: 0,
            duration: modalAnimationDurationS,
          },
          modalStaggerDurationS
        )
    }
  }, [open])

  return (
    <ModalWrapper
      isOpen={isOpen}
      open={open}
      onDismiss={onDismiss}
      inset={inset}
    >
      <ModalOverlay ref={$overlay} onClick={closeEvent || null} />
      <ModalContent ref={$content} aria-label={ariaLabel} inset={inset}>
        {children}
      </ModalContent>
    </ModalWrapper>
  )
}

const Modal = ({
  isOpen,
  onDismiss,
  closeEvent,
  ariaLabel,
  children,
  inset,
}) => (
  <Phased
    value={isOpen}
    phases={0}
    timeouts={[modalAnimationDurationMs + modalStaggerDurationMs]}
  >
    {({ value, nextValue }) => (
      <ModalInner
        isOpen={value || nextValue}
        open={nextValue}
        onDismiss={onDismiss}
        closeEvent={closeEvent}
        ariaLabel={ariaLabel}
        children={children}
        inset={inset}
      />
    )}
  </Phased>
)

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onDismiss: PropTypes.func.isRequired,
  closeEvent: PropTypes.func,
  ariaLabel: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  inset: PropTypes.bool,
}

export default Modal
