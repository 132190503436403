import React, { useEffect, useCallback } from 'react'
import debounce from 'lodash/debounce'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import FontFaceObserver from 'fontfaceobserver'

// Components
import {
  useStore,
  updatePathname,
  updatePopState,
  updateResized,
  loadFonts,
} from '@Store'
import TransitionMask from '@components/PageTransitionMask'
import Gridlines from '@components/Gridlines'
import Header from '@components/Header'
import SmoothScroll from '@components/SmoothScroll'
import Locator from '@components/Locator'

// Fonts
import GTAmericaWOFF2 from '../../fonts/gtamerica/GT-America-Compressed-Medium.woff2'
import GTAmericaWOFF from '../../fonts/gtamerica/GT-America-Compressed-Medium.woff'
import GTAmericaTTF from '../../fonts/gtamerica/GT-America-Compressed-Medium.ttf'
import PPNeueMontrealMedWOFF2 from '../../fonts/ppneuemontreal/PPNeueMontreal-Medium.woff2'
import PPNeueMontrealMedWOFF from '../../fonts/ppneuemontreal/PPNeueMontreal-Medium.woff'
import PPNeueMontrealMedTTF from '../../fonts/ppneuemontreal/PPNeueMontreal-Medium.ttf'
import PPNeueMontrealSemiWOFF2 from '../../fonts/ppneuemontreal/PPNeueMontreal-SemiBold.woff2'
import PPNeueMontrealSemiWOFF from '../../fonts/ppneuemontreal/PPNeueMontreal-SemiBold.woff'
import PPNeueMontrealSemiTTF from '../../fonts/ppneuemontreal/PPNeueMontreal-SemiBold.ttf'

// Styles
import { GlobalStyles } from '@styles/GlobalStyles.style'
import { font } from '@styles/vars/font.style'
import { colors } from '@styles/vars/colors.style'

const Layout = ({ children, location }) => {
  const [, dispatch] = useStore()

  const handleBrowserNavigationInteraction = useCallback(
    e => {
      updatePathname(dispatch, window.location.pathname)
      updatePopState(dispatch)
    },
    [dispatch]
  )

  const setViewportHeight = () => {
    const vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }

  const handleWebfontLoad = useCallback(() => {
    const fontA = new FontFaceObserver(font.primary.family)

    fontA.load().then(
      () => {
        loadFonts(dispatch)
      },
      () => {
        console.log('Font is not available')
      }
    )
  }, [dispatch])

  useEffect(() => {
    setViewportHeight()
    handleWebfontLoad()

    const resizeObserver = new ResizeObserver(
      debounce(() => {
        updateResized(dispatch)
      }, 50)
    )
    const $content = document.querySelector('.tl-edges')

    resizeObserver.observe($content)
    window.addEventListener('resize', setViewportHeight)
    window.addEventListener('popstate', handleBrowserNavigationInteraction)

    return () => {
      resizeObserver.unobserve($content)
      window.removeEventListener('resize', setViewportHeight)
      window.removeEventListener('popstate', handleBrowserNavigationInteraction)
    }
  }, [dispatch, handleWebfontLoad, handleBrowserNavigationInteraction])

  return (
    <>
      <GlobalStyles />
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="theme-color" content={colors.light} />
        <style>{`
          @font-face {
            font-family: 'GTAmerica';
            src: url(${GTAmericaWOFF2}) format('woff2'),
              url(${GTAmericaWOFF}) format('woff'),
              url(${GTAmericaTTF}) format('ttf');
            font-weight: ${font.primary.weight.medium};
          }
          @font-face {
            font-family: 'PPNeueMontreal';
            src: url(${PPNeueMontrealMedWOFF2}) format('woff2'),
              url(${PPNeueMontrealMedWOFF}) format('woff'),
              url(${PPNeueMontrealMedTTF}) format('ttf');
            font-weight: ${font.secondary.weight.medium};
          }
          @font-face {
            font-family: 'PPNeueMontreal';
            src: url(${PPNeueMontrealSemiWOFF2}) format('woff2'),
              url(${PPNeueMontrealSemiWOFF}) format('woff'),
              url(${PPNeueMontrealSemiTTF}) format('ttf');
            font-weight: ${font.secondary.weight.semibold};
          }
        `}</style>
        <script
          src={`https://maps.googleapis.com/maps/api/js?key=${process.env.GATSBY_GOOGLE_API}`}
        ></script>
      </Helmet>

      <Gridlines show={false} />

      <SmoothScroll callbacks={location} />

      <TransitionMask />

      {process.env.GATSBY_GOOGLE_API && <Locator location={location} />}

      <Header
        location={location}
        microsite={children.props.data?.contentfulMicrosite}
      />

      <div id="smooth-wrapper">
        <div id="smooth-content">{children}</div>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
